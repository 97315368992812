import React from "react";
import { TitleComponent } from ".././../components";
import "./styles.scss";

const ImageComponent = ({
  renderContent,
  mainImage,
  backgroundImage,
  invert,
  title,
  align,
  center,
  reversed,
  removeLine,
  titleId,
  backgroundBlack,
  smaller,
  mobile,
  bigger,
  semibigger,
  muchbigger,
  opacity,
  fullblended,
  verymuchbigger,
  height,
  titleMargin,
  fixedHeight,
  blended
}) => {
  var color;
  if (reversed) {
    color = invert ? "white" : "black";
  } else {
    color = invert ? "black" : "white";
  }

  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  return (
    <div
      className={`image-section-container ${invert && "inverted"}  
    ${backgroundBlack && "backgroundBlack"} ${mobile && "mobile"} 
    ${bigger && "bigger"}
    ${semibigger && "semibigger"} ${muchbigger && "muchbigger"} ${
        verymuchbigger && "verymuchbigger"
      } ${opacity && "opacity"} ${fullblended && "fullblended"}`}
      style={{ height: `${height}vh` }}
    >
        {isMobileVersion === 1 ? 
        <div className={`container ${center && 'center'}`} >
          <div className={`container-inner relative pt-5 pb-16 ${center && 'center'}`} style={{height:fixedHeight && "80%", alignItems: align || '', justifyContent: align || ''}}>
          {title === "" ? (
              ""
            ) : (
              <TitleComponent
                title={title}
                removeLine={removeLine}
                titleId={titleId}
                smallerTitle={smaller}
                titleMargin={titleMargin}
                mobile
              />
            )}
          {renderContent && renderContent()}
          {/* {mainImage && <img className="main-image" src={require(mainImage)} /> } */}
          </div>
        </div> :
        <div className={`container desktop ${center && 'center'}`} style={{ backgroundImage: `url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Our-Universe-Background.png")` }} >
          <div className={`container-inner relative pt-16 pb-16 ${center && 'center'}`} style={{height:fixedHeight && "80%", alignItems: align || '', justifyContent: align || ''}}>
          {title === "" ? (
              ""
            ) : (
              <TitleComponent
                title={title}
                removeLine={removeLine}
                titleId={titleId}
                smallerTitle={smaller}
                titleMargin={titleMargin}
              />
            )}
          {renderContent && renderContent()}
          {/* {mainImage && <img className="main-image" src={require(mainImage)} /> } */}
          </div>
        </div>}
    </div>
  );
};

export default ImageComponent;
